<template>
  <div>
    <PageTitle
      title="線下貼標連結"
      btn="新增"
      :btnLoading="createLiffLoading"
      @btnClick="createClientMTagLink"
    />
    <div v-loading="loading.table">
      <el-table :data="tableData">
        <EmptyBlock slot="empty" />
        <el-table-column label="連結名稱" prop="name" align="center" />
        <el-table-column label="線下貼標連結" prop="shortLink" align="center">
          <template slot-scope="scope">
            <div class="flex items-center" style="gap: 10px">
              <p class="w-full">{{ scope.row.shortLink }}</p>
              <span
                class="material-icons text-primary-100 cursor-pointer"
                style="width: 24px; transform: scaleX(-1)"
                @click="copyLink(scope.row.shortLink)"
              >
                content_copy_outlined
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="標籤" align="center">
          <template slot-scope="scope">
            <div class="flex flex-wrap" style="gap: 8px">
              <Tag v-for="tag in tagList(scope.row)" :key="tag" type="action">{{ tag }}</Tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="開始時間" prop="start" width="170px" align="center">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.start) }}
          </template>
        </el-table-column>
        <el-table-column label="結束時間" prop="end" width="170px" align="center">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.end) }}
          </template>
        </el-table-column>
        <el-table-column label="連結狀態" prop="status" align="center" width="100px">
          <template slot-scope="scope">
            <Tag :type="get(linkStatus(scope.row), 'type')">
              {{ get(linkStatus(scope.row), 'label') }}
            </Tag>
          </template>
        </el-table-column>
        <el-table-column label="貼標次數" prop="recordCount" align="center" width="100px" />
        <el-table-column label="操作" prop="status" fixed="right" width="100px" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              @edit="editClientMTagLink(scope.row)"
              @delete="handleDelete(scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableOptions.dataCount"
      @pageChange="refresh"
    />

    <DeleteDialog
      v-if="dialog.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="dialog.delete = false"
      @delete="deleteClientMTagLink"
    />
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, onActivated } from 'vue'
import { GetClientMTagLink, GetClientMTagLinkCount, DeleteClientMTagLink, FindViewCount } from '@/api/clientMTagLink'
import { GetLiff, CreateLiff } from '@/api/shop'
import { find, get, map } from 'lodash'
import dayjs from '@/lib/dayjs'
import copy from 'clipboard-copy'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { useTable } from '@/use/table'
import { useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'ClientMTagLinkManagement',
  components: {
    EmptyBlock,
  },
  setup () {
    const { shopId, tableData, tableOptions, dateFormat, loading, fetchData, fetchDataCount, pageStartIndex } = useTable()
    const router = useRouter()
    const target = ref(null)
    const needCreateLiff = ref(false)
    const createLiffLoading = ref(false)
    const dialog = reactive({
      delete: false,
    })

    const copyLink = (link) => {
      copy(link)
      window.$message.success('複製連結成功！')
    }
    const refresh = async () => {
      loading.table = true
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
      }
      await fetchData(GetClientMTagLink, payload)
      await fetchDataCount(GetClientMTagLinkCount, payload)
      loading.table = false
    }
    const checkLiff = async () => {
      const res = await GetLiff({ shopId: shopId.value })
      const exist = find(res, { name: 'trackingLink' })
      if (!exist) needCreateLiff.value = true
    }
    const createLiff = async () => {
      try {
        await CreateLiff({
          shopId: shopId.value,
          name: 'trackingLink',
        })
      } catch (err) {
        window.$message.error(err)
        return false
      }
      return true
    }
    const createClientMTagLink = async () => {
      createLiffLoading.value = true
      if (needCreateLiff.value) {
        if (!await createLiff()) {
          createLiffLoading.value = false
          return
        }
      }
      router.push({
        name: 'EditClientMTagLink',
      })
      createLiffLoading.value = false
    }
    const editClientMTagLink = async (row) => {
      router.push({
        name: 'EditClientMTagLink',
        params: {
          id: row.id,
        },
      })
    }
    const deleteClientMTagLink = async () => {
      const [, err] = await DeleteClientMTagLink({
        shopId: shopId.value,
        id: get(target, 'value.id'),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('刪除成功！')
      dialog.delete = false
      await refresh()
    }
    const handleDelete = async (row) => {
      target.value = row
      dialog.delete = true
      console.log('handleDelete', row)
    }
    const tagList = (row) => {
      const register = row.RegisterMTags
      const target = row.TargetMTags
      let count = 0
      const list = []
      // console.log(register, target)
      if (register && register.length) {
        list.push(get(register, '[0].name'))
        if (register.length - 1) count += register.length - 1
      }
      if (target && target.length) {
        list.push(get(target, '[0].name'))
        if (target.length - 1) count += target.length - 1
      }
      if (count) list.push(`+${count}`)
      return list
    }
    const linkStatus = (row) => {
      const start = dayjs(row.start)
      const end = dayjs(row.end)
      const now = dayjs()
      if (now.isAfter(end)) {
        return {
          type: 'info',
          label: '已結束',
        }
      }
      if (now.isAfter(start) && now.isBefore(end)) {
        return {
          type: 'warning',
          label: '進行中',
        }
      }
      if (now.isBefore(start)) {
        return {
          type: 'action',
          label: '已排程',
        }
      }
    }
    onMounted(async () => {
      checkLiff()
      await refresh()
    })
    onActivated(async () => {
      await refresh()
    })
    return {
      tableData,
      get,
      tableOptions,
      linkStatus,
      createClientMTagLink,
      createLiffLoading,
      editClientMTagLink,
      deleteClientMTagLink,
      dialog,
      handleDelete,
      tagList,
      copyLink,
      loading,
      refresh,
      dateFormat,
    }
  },
})
</script>

<style lang="scss" scoped>

</style>
